import { StyledScreenContainer } from 'themes/globalStyles';
import { BackgroundContainer, InfoContainer, InfoDescription, InfoTitle, OpenningContent } from '../styled';

import SmallForm from 'components/landing-page-screens/reusable-components/small-form';
import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'config/constants';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  const { t } = useTranslation();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: '620px',
        margin: '0px',
        backgroundColor: '#F5F9FC',
        alignItems: 'flex-start',
      }}
    >
      <OpenningContent>
        <InfoContainer>
          <BackgroundContainer />
          <InfoTitle>
            <TextProcessing text={t('openningScreen.title')} customStyle={styledTitle(TextVariants.PRIMARY)} />
          </InfoTitle>
          <InfoDescription>
            <TextProcessing text={t('openningScreen.text')} customStyle={styledText(TextVariants.PRIMARY)} />
          </InfoDescription>
          <SmallForm
            customStyle
            text={<TextProcessing text={t('global.smallForm.text')} customStyle={styledText(TextVariants.TERTIARY)} />}
          />
        </InfoContainer>
      </OpenningContent>
    </StyledScreenContainer>
  );
};

export default Desktop;
