import { OpenningScreenImg } from 'images/openning-screen-img';
import styled from 'styled-components';

export const OpenningContent = styled.div`
  width: 1056px;
  height: 570px;
  display: flex;
  justify-content: center;

  @media (max-width: 1055px) {
    width: 672px;
    height: auto;
  }
  @media (max-width: 671px) {
    width: 320px;
    height: auto;
  }
`;

export const InfoContainer = styled.div`
  width: 928px;
  height: 570px;
  background: linear-gradient(270deg, #3f41d1 0%, #32aacf 100%);
  border-radius: 20px 20px 80px;
  position: relative;

  display: flex;
  flex-direction: column;

  @media (max-width: 1055px) {
    width: 608px;
    height: auto;
    padding: 0px 0px 24px;
  }
  @media (max-width: 671px) {
    width: 288px;

    border-radius: 20px;
    padding: 0px 0px 24px;
  }
`;

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${OpenningScreenImg.wave}');
  background-repeat: no-repeat;
  background-position: top right;
  border-radius: 20px 20px 80px;

  position: absolute;
  z-index: 0;
`;

export const InfoTitle = styled.div`
  width: 548px;
  margin: 64px 0px 0px 64px;

  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  color: #ffffff;
  z-index: 1;

  @media (max-width: 1055px) {
    width: 480px;
    margin: 48px 0px 0px 48px;
  }

  @media (max-width: 671px) {
    width: 244px;
    font-size: 24px;
    line-height: 133%;
    margin: 32px 0px 0px 24px;
  }
`;

export const InfoDescription = styled.div`
  width: 520px;
  margin: 16px 0px 0px 64px;

  font-weight: 400;
  font-size: 16px;
  color: #ffffff;

  z-index: 1;

  @media (max-width: 1055px) {
    width: 512px;
    margin: 16px 0px 0px 48px;
  }

  @media (max-width: 671px) {
    width: 244px;

    font-size: 14px;
    margin: 16px 0px 0px 24px;
  }
`;
