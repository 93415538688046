import facebookIcon from './facebookIcon.svg';
import instagramIcon from './instagramIcon.svg';
import linkedinIcon from './linkedinIcon.svg';

import logo from './logo.svg';
import logoWhite from './logoWhite.svg';

export const GeneralImages = {
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  logo,
  logoWhite,
};
