import styled from 'styled-components';

export const StyledCard = styled.div`
  width: 256px;
  height: 268px;
  background: #ffffff;

  padding: 32px 16px 32px 32px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    margin: 16px 0px 0px 0px;
    padding: 32px;
    flex-direction: row;
    flex-wrap: column;

    align-items: center;
    justify-content: center;
  }

  @media (max-width: 671px) {
    width: 272px;
    height: auto;
    padding: 16px;
    flex-direction: row;

    align-items: space-between;
    justify-content: space-between;
  }
`;

export const StyledImg = styled.div`
  width: 168px;

  height: 40px;
  min-height: 40px;

  background-image: ${({ customStyle }) => `url(${customStyle.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;

  @media (max-width: 1055px) {
    height: auto;

    margin: 0px 20px 0px 0px;
  }

  @media (max-width: 671px) {
    display: none;
  }
`;

export const CardTitle = styled.div`
  width: 156px;
  height: 38px;
  margin: 16px 0px 0px 0px;
  display: flex;

  @media (max-width: 1055px) {
    margin: 0px;
    height: auto;
  }

  @media (max-width: 671px) {
    width: 104px;
    height: auto;

    font-size: 14px;
    color: #78a9ff;
  }
`;

export const CardDescription = styled.div`
  width: 208px;

  margin: 16px 0px 0px 0px;

  @media (max-width: 671px) {
    width: 132px;

    font-size: 12px;

    color: #525252;
  }
`;

export const MobileTextBox = styled.div`
  width: 100%;
  height: 100%;

  display: flex;

  @media (max-width: 1055px) {
    flex-direction: column;
  }

  @media (max-width: 671px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
