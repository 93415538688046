import styled from 'styled-components';

export const StyledInputBox = styled.div`
  width: 240px;
  height: 64px;
  background: #fff;
  position: relative;

  display: flex;

  margin: 4px 0px 0px 0px;

  @media (max-width: 671px) {
    width: 240px;
  }
`;

export const StyledImg = styled.img`
  width: 20px;
  margin: 20px 0px 0px 0px;
`;

export const LabelInputBox = styled.div`
  width: 240px;
  height: 46px;

  display: flex;
  align-items: center;

  position: relative;
  border-bottom: 1px solid #525252;

  &:focus-within label {
    height: 16px;
    transform: translate(0, -16px) scale(1);
    font-size: 12px;
    margin: 8px 0px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 240px;
  }
`;

export const StyledInput = styled.input`
  width: 216px;
  height: 20px;
  border: none;

  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => (theme.hasError ? '#FF8389' : '#525252')};

  background: none;
  outline: none;

  margin: 20px 0px 0px 0px;

  padding: 0px;
  z-index: 2;

  transition: all 0.2s ease-out;

  @media (max-width: 671px) {
    width: 216px;
  }
`;

export const StyledError = styled.div`
  width: 240px;
  position: absolute;

  font-weight: 400;
  font-size: 12px;
  color: #ffb3b8;

  margin: 46px 0px 0px 0px;

  @media (max-width: 671px) {
    width: 240px;
  }
`;

export const StyledLabel = styled.label`
  width: 216px;

  height: ${({ theme }) => (theme.isActive ? '15px' : '20px')};

  font-weight: 400;
  font-size: ${({ theme }) => (theme.isActive ? '12px' : '14px')};
  color: #8d8d8d;
  position: absolute;

  margin: ${({ theme }) => (theme.isActive ? '8px 0px 0px 0px' : '20px 0px 0px 0px')};

  display: flex;
  align-items: center;

  transform: ${({ theme }) => (theme.isActive ? 'translate(0, -16px) scale(1) ' : 'translate(0px, 0px) scale(1)')};

  transition: all 0.2s ease-out;
`;
