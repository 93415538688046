import { SocialMediaVariant } from 'model/enums/social-media-variants';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const StyledSVG = styled(SVG)`
  width: 16px;
  height: 16px;
  & path {
    fill: ${({ variant }) => (variant === SocialMediaVariant.PRIMARY ? '#82CFFF' : '#FFFFFF')};
  }
`;

export const SocialMediaBox = styled.div`
  width: 82px;
  height: 18px;
  display: flex;
  justify-content: space-between;

  margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '0px')};

  @media (max-width: 1055px) {
    margin: 0px;
  }
`;

export const StyledLink = styled.a`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
