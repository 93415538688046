import { GeneralImages } from 'images/general-img';
import { SocialMediaBox, StyledLink, StyledSVG } from './styled';

interface SocialMediaProps {
  variant?: string;
  customStyle?: any;
}

const SocialMedia: React.FC<SocialMediaProps> = ({ customStyle, variant }) => {
  const socialData = [
    {
      link: '',
      svg: GeneralImages.facebookIcon,
    },
    {
      link: '',
      svg: GeneralImages.linkedinIcon,
    },
    {
      link: '',
      svg: GeneralImages.instagramIcon,
    },
  ];

  return (
    <SocialMediaBox customStyle={{ margin: customStyle.margin }}>
      {socialData.map((social, idx) => (
        <StyledLink href={social.link} key={idx} target="_blank">
          <StyledSVG src={social.svg} variant={variant} />
        </StyledLink>
      ))}
    </SocialMediaBox>
  );
};

export default SocialMedia;
