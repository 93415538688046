import styled from 'styled-components';

export const SolutionsScreenContainer = styled.div`
  width: 800px;
  height: 342px;

  margin: 50px 0px 0px 0px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    margin: 24px 0px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 272px;
    height: auto;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 48px 0px 0px 0px;

  @media (max-width: 1055px) {
    flex-direction: column;
  }

  @media (max-width: 671px) {
    margin: 0px 0px 0px 0px;
  }
`;
