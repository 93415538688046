import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface OpenningScreenProps {}

const OpenningScreen: React.FC<OpenningScreenProps> = props => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default OpenningScreen;
