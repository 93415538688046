import TextProcessing from 'components/text-processing';
import { styledTitle } from 'config/constants';
import { SolutionsIcon } from 'images/solutions-screens';
import { TextVariants } from 'model/enums/text-variants';
import { SolutionDataType } from 'model/interfaces/cardType';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer } from 'themes/globalStyles';
import Card from '../card';
import { CardContainer, SolutionsScreenContainer } from '../styled';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = () => {
  const { t, i18n } = useTranslation();

  const solutionsData: SolutionDataType[] = t('solutionScreen.solutionsData', { returnObjects: true });

  return (
    <StyledScreenContainer
      customStyle={{ width: '100%', height: 'auto', margin: '0px', backgroundColor: 'none', alignItems: 'flex-start' }}
    >
      <SolutionsScreenContainer>
        <TextProcessing text={t('solutionScreen.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
        <CardContainer>
          {solutionsData.map((data, idx) => (
            <Card key={idx} data={data} icon={SolutionsIcon[idx]} />
          ))}
        </CardContainer>
      </SolutionsScreenContainer>
    </StyledScreenContainer>
  );
};

export default Mobile;
