import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

export interface StepType {
  title: JSX.Element;
  description: JSX.Element;
}

interface AboutUsScreenProps {}

const AboutUsScreen: React.FC<AboutUsScreenProps> = props => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default AboutUsScreen;
