import TextProcessing from 'components/text-processing';
import { styledText } from 'config/constants';
import { TextVariants } from 'model/enums/text-variants';
import { SolutionDataType } from '../../layout/desktop';
import { CardDescription, CardTitle, StyledCard, StyledImg } from '../styled';

interface DesktopProps {
  icon: string;
  data: SolutionDataType;
}

const Desktop: React.FC<DesktopProps> = ({ icon, data }) => {
  return (
    <StyledCard>
      <StyledImg customStyle={{ backgroundImage: icon }} />
      <CardTitle>
        <TextProcessing text={data.title} customStyle={styledText(TextVariants.PRIMARY)} />
      </CardTitle>
      <CardDescription>
        <TextProcessing text={data.text} customStyle={styledText(TextVariants.TERTIARY)} />
      </CardDescription>
    </StyledCard>
  );
};

export default Desktop;
