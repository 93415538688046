import { font, fontSize } from 'shared/util/styled-component-utils';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: ${font`default`};
  }  
`;

export const StyledPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ customStyle }) => (customStyle.flexDirection ? customStyle.flexDirection : 'column')};
  margin: 0px;
  background: ${({ customStyle }) => (customStyle.backgroundColor ? customStyle.backgroundColor : '#FFFFFF')};
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledScreenContainer = styled.div`
  width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '1440px')};
  height: ${({ customStyle }) => (customStyle.height ? customStyle.height : '500px')};

  ${({ customStyle }) => {
    if (customStyle.backgroundImage) {
      return `
      background-image: url(${customStyle.backgroundImage});
      background-repeat: no-repeat;
      `;
    } else {
      return `  background: ${customStyle.backgroundColor ? customStyle.backgroundColor : '#FFFFFF'};
        `;
    }
  }}

  margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '60px 0px 0px 0px')};
  padding: ${({ customStyle }) => (customStyle.padding ? customStyle.padding : '0px 0px 0px 0px')};
  display: flex;
  flex-direction: ${({ customStyle }) => (customStyle.flexDirection ? customStyle.flexDirection : 'row')};
  align-items: ${({ customStyle }) => (customStyle.alignItems ? customStyle.alignItems : 'center')};
  justify-content: ${({ customStyle }) => (customStyle.justifyContent ? customStyle.justifyContent : 'center')};
  position: ${({ customStyle }) => (customStyle.position ? customStyle.position : 'static')};

  @media (max-width: 1056px) {
    width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '768px')};
    height: ${({ customStyle }) => (customStyle.height ? customStyle.height : 'auto')};
    margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '20px 0px 0px 0px')};
  }

  @media (max-width: 768px) {
    width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '320px')};
    height: ${({ customStyle }) => (customStyle.height ? customStyle.height : 'auto')};
  }
`;

export const StyledTitle = styled.div`
  width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '332px')};
  font-weight: 500;
  font-size: ${fontSize`lg`};
  color: #525252;
  ${({ customStyle }) => {
    if (customStyle.margin) {
      return `margin: ${customStyle.margin}`;
    }
  }}

  @media (max-width: 671px) {
    width: 240px;
    font-size: 20px;
  }
`;

export const StyledButton = styled.button`
  width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '116px')};
  height: ${({ customStyle }) => (customStyle.height ? customStyle.height : '48px')};

  border-radius: 8px;
  border: none;

  margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '0px 0px 0px 12px')};

  font-weight: 600;
  font-size: ${fontSize`xxs`};
  padding: 12px, 24px, 12px, 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${({ theme, customStyle }) =>
    `color: ${theme.button[customStyle.variant].color};
  background: ${theme.button[customStyle.variant].background};
  `}

  &:hover, &:focus, &:active {
    ${({ theme }) =>
      `color: ${theme.button.changed.color};
     background: ${theme.button.changed.background};
   `}
  }

  @media (max-width: 671px) {
    width: ${({ customStyle }) => (customStyle.width ? customStyle.width : '240px')};

    margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '24px 0px 0px 0px')};
  }
`;
