import styled from 'styled-components';

export const BenefitContent = styled.div`
  width: 800px;
  height: 454px;
  display: flex;
  justify-content: space-between;

  margin: 96px 0px 0px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    padding: 0px 0px 32px 0px;

    flex-direction: column;
  }
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const BoxTitle = styled.div`
  width: 224px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;

    flex-direction: column;
  }

  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const BoxText = styled.div`
  width: 480px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    margin: 32px 0px 0px;
  }

  @media (max-width: 671px) {
    margin: 32px 0px 0px;
    width: 272px;
  }
`;
