import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'config/constants';
import Translate from 'i18n/translate';
import { FormProperties } from 'model/enums/input-names';
import { TextVariants } from 'model/enums/text-variants';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import { StyledScreenContainer, StyledTitle } from 'themes/globalStyles';
import { FormType } from '..';
import Input from '../input';
import { FormInfo, FormTitle, InfoContainer, InfoDescription, StyledForm, StyledTextArea } from '../styled';

interface MobileProps {
  isLoading: number;
  methods: any;
  handleForm: any;
  formData: FormType;
  sentProcess: any;
  setFormData: any;
}

const Mobile: React.FC<MobileProps> = ({ isLoading, methods, handleForm, formData, sentProcess, setFormData }) => {
  const { t } = useTranslation();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: 'auto',
        margin: '0px',
        backgroundColor: 'linear-gradient(70.17deg, #D0E2FF 0%, #EDF5FF 68.49%)',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '64px 80px',
      }}
    >
      <InfoContainer>
        <StyledTitle customStyle>
          <TextProcessing text={t('formScreen.textContainer.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
        </StyledTitle>
        <InfoDescription>
          <TextProcessing text={t('formScreen.textContainer.text')} customStyle={styledText(TextVariants.QUINARY)} />
        </InfoDescription>
      </InfoContainer>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(handleForm)} name="LP - Form Completo">
          {(isLoading == 0 || isLoading == 1) && (
            <FormTitle customStyle>
              <Translate contentKey={'formScreen.formContainer.titleStages.stageOne'} />
            </FormTitle>
          )}
          {isLoading == 2 && (
            <FormTitle customStyle>
              <Translate contentKey={'formScreen.formContainer.titleStages.stageTwo'} />
            </FormTitle>
          )}

          <Input
            name={FormProperties.NAME}
            required={true}
            htmlFor={FormProperties.NAME}
            label={<Translate contentKey={'formScreen.formContainer.inputLabels.name'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
          />
          <Input
            name={FormProperties.WHATSAPP}
            required={true}
            htmlFor={FormProperties.WHATSAPP}
            label={<Translate contentKey={'formScreen.formContainer.inputLabels.whatsapp'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
            maxLength={13}
            mask={StringUtils.phoneMask}
          />
          <Input
            name={FormProperties.EMAIL}
            required={true}
            htmlFor={FormProperties.EMAIL}
            label={<Translate contentKey={'formScreen.formContainer.inputLabels.email'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
          />
          <Input
            name={FormProperties.COMPANY}
            required={true}
            htmlFor={FormProperties.COMPANY}
            label={<Translate contentKey={'formScreen.formContainer.inputLabels.company'} />}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
          />
          <FormTitle customStyle={{ fontSize: '14px', margin: '24px 0px 0px 0px' }}>
            <Translate contentKey={'formScreen.formContainer.fromDescriptionTitle'} />
          </FormTitle>
          <StyledTextArea
            {...methods.register(FormProperties.DESCRIPTION, { required: true })}
            name={FormProperties.DESCRIPTION}
            onChange={e => setFormData({ ...formData, [FormProperties.DESCRIPTION]: e.target.value })}
          />
          {sentProcess()}
          <FormInfo>
            <Translate contentKey={'formScreen.formContainer.formInfo'} />
          </FormInfo>
        </StyledForm>
      </FormProvider>
    </StyledScreenContainer>
  );
};

export default Mobile;
