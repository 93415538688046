import { useEffect, useState } from 'react';
import ReactGa from 'react-ga';

export const useAnalytics = () => {
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    ReactGa.initialize('UA-173203669-1');
    setInitialized(true);
  }, []);
  return {
    initialized,
  };
};
