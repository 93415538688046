import styled from 'styled-components';

export const HeaderContent = styled.div`
  width: 1056px;
  height: 100%;
  padding: 0px 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1055px) {
    padding: 0px 32px;
  }
  @media (max-width: 671px) {
    padding: 0px 16px;
  }
`;

export const BoxImg = styled.div`
  width: 100px;
  height: 28px;
  background: #f5f9fc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledImg = styled.img``;
