import { TextVariants } from 'model/enums/text-variants';

const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_IMAGE_SIZE_LIMIT = 15555555;

// text styles
export const styledTitle = (variant: string) => {
  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          fontWeight: '500',
          fontSize: '40px',
          lineHeight: '48px',
          color: '#fff',
        },
        mobile: {
          fontWeight: '500',
          fontSize: '24px',
          lineHeight: '32px',
          color: '#fff',
        },
        custom: {
          color: '#82CFFF',
        },
      };
    case TextVariants.SECONDARY:
      return {
        default: {
          fontWeight: '500',
          fontSize: '32px',
          lineHeight: '48px',
          color: '#525252',
        },
        mobile: {
          fontWeight: '500',
          fontSize: '20px',
          lineHeight: '26px',
          color: '#525252',
        },
      };
  }
};

export const styledSubtitle = (variant: string) => {
  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '24px',
          lineHeight: '36px',
          color: '#525252',
        },
        mobile: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '21px',
          color: '#525252',
        },
      };
  }
};

export const styledText = (variant: string) => {
  switch (variant) {
    case TextVariants.PRIMARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#fff',
        },
        mobile: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#fff',
        },
      };
    case TextVariants.SECONDARY:
      return {
        default: {
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '19px',
          color: '#525252',
        },
        mobile: {
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#78A9FF',
        },
      };
    case TextVariants.TERTIARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#FFFFFF',
        },
        mobile: {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '18px',
          color: '#fff',
        },
      };
    case TextVariants.QUATERNARY:
      return {
        default: {
          fontWeight: '600',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#FFFFFF',
        },
      };
    case TextVariants.QUINARY:
      return {
        default: {
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '21px',
          color: '#525252',
        },
        mobile: {
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
        },
      };
    case TextVariants.SENARY:
      return {
        default: {
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '24px',
          color: '#fff',
        },
      };
  }
};
