import TextProcessing from 'components/text-processing';
import { styledSubtitle, styledText, styledTitle } from 'config/constants';
import { CompleteSolutionsIcon } from 'images/complete-solutions';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer } from 'themes/globalStyles';
import Card from '../card';
import { CardContainer, CompleteSolutionsContainer, TextContainer } from '../styled';

export interface SolutionDataType {
  title: string;
  text: string;
}

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = () => {
  const { t, i18n } = useTranslation();

  const solutionsData: SolutionDataType[] = t('completeSolutionScreen.solutions', { returnObjects: true });

  return (
    <StyledScreenContainer
      customStyle={{ width: '100%', height: '596px', margin: '0px', backgroundColor: 'none', alignItems: 'flex-start' }}
    >
      <CompleteSolutionsContainer>
        <TextProcessing text={t('completeSolutionScreen.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
        <TextProcessing text={t('completeSolutionScreen.subTitle')} customStyle={styledSubtitle(TextVariants.PRIMARY)} />
        <TextContainer>
          <TextProcessing text={t('completeSolutionScreen.text')} customStyle={styledText(TextVariants.QUINARY)} />
        </TextContainer>
        <CardContainer>
          {solutionsData.map((data, idx) => (
            <Card key={idx} data={data} icon={CompleteSolutionsIcon[idx]} />
          ))}
        </CardContainer>
      </CompleteSolutionsContainer>
    </StyledScreenContainer>
  );
};

export default Desktop;
