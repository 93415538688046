import SocialMedia from 'components/landing-page-screens/reusable-components/social-media';
import { GeneralImages } from 'images/general-img';
import { SocialMediaVariant } from 'model/enums/social-media-variants';
import { StyledScreenContainer } from 'themes/globalStyles';
import { BoxImg, HeaderContent, StyledImg } from '../styled';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = props => {
  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: '64px',
        margin: '0px',
        backgroundColor: '#F5F9FC',
      }}
    >
      <HeaderContent>
        <BoxImg>
          <StyledImg src={GeneralImages.logo} />
        </BoxImg>
        <SocialMedia customStyle variant={SocialMediaVariant.PRIMARY} />
      </HeaderContent>
    </StyledScreenContainer>
  );
};

export default Desktop;
