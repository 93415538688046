import { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useResponsiveMobile = () => {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: 1055,
  });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isMobile: isClient ? isMobile : false,
    isClient,
  };
};

export default useResponsiveMobile;
