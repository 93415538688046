import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

const createApiInstance = (baseURL: string = process.env.REACT_APP_API_URL ?? ''): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: baseURL,
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);

  // tslint:disable-next-line: no-shadowed-variable
  instance.interceptors.request.use(async config => {
    config.headers = {};
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_API_TOKEN}`;

    return config;
  });
  return instance;
};

export const api = createApiInstance();
export default {
  api,
};
