import styled from 'styled-components';

export const StyledInputBox = styled.div`
  ${({ customStyle }) => (customStyle?.width ? '156px' : '192px')};
  height: 48px;
  background: #fff;
  border-radius: ${({ customStyle }) => (customStyle?.borderRadius ? '10px 0px 0px 10px' : '0px 10px 10px 0px')};
  position: relative;

  display: flex;

  @media (max-width: 671px) {
    width: 240px;
    border-radius: 10px;
    margin: 24px 0px 0px 0px;
  }
`;

export const LabelInputBox = styled.div`
  width: ${({ customStyle }) => (customStyle?.width ? '156px' : '192px')};

  height: 48px;
  display: flex;
  align-items: center;

  position: relative;

  &:focus-within label {
    height: 16px;
    transform: translate(8px, -16px) scale(1);
    font-size: 12px;
    margin: 8px 0px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 240px;
  }
`;

export const StyledInput = styled.input`
  width: ${({ customStyle }) => (customStyle?.width ? '124px' : '176px')};

  height: 24px;
  border: none;

  font-weight: 400;
  font-size: 12px;
  color: ${({ customStyle }) => (customStyle?.hasError ? '#FF8389' : '#525252')};

  background: none;
  outline: none;

  margin: ${({ customStyle }) => (customStyle?.isActive ? '12px 0px 0px 8px' : '0px 0px 0px 8px')};

  padding: 0px;
  z-index: 2;

  transition: all 0.2s ease-out;

  &:focus-within {
    margin: 12px 0px 0px 8px;
  }

  @media (max-width: 671px) {
    width: 220px;
  }
`;

export const StyledError = styled.div`
  width: ${({ customStyle }) => (customStyle?.width ? '124px' : '176px')};
  height: 16px;
  position: absolute;

  font-weight: 400;
  font-size: 12px;
  color: #ffb3b8;

  margin: 48px 0px 0px 8px;
`;

export const StyledLabel = styled.label`
  width: ${({ customStyle }) => (customStyle?.width ? '124px' : '176px')};

  height: ${({ customStyle }) => (customStyle?.isActive ? '15px' : '20px')};

  font-weight: 400;
  font-size: ${({ customStyle }) => (customStyle?.isActive ? '12px' : '14px')};
  color: #525252;
  position: absolute;

  margin: ${({ customStyle }) => (customStyle?.isActive ? '8px 0px 0px 0px' : '0px 0px 0px 8px')};

  display: flex;
  align-items: center;

  transform: ${({ customStyle }) => (customStyle?.isActive ? 'translate(8px, -16px) scale(1) ' : 'translate(0px, 0px) scale(1)')};

  transition: all 0.2s ease-out;
`;
