// import { SolutionsScreenImg } from 'images/solutions-screens';
import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

export interface CardDataType {
  icon: string;
  title: JSX.Element;
  description: JSX.Element;
}

interface CompleteSolutionProps {}

const CompleteSolution: React.FC<CompleteSolutionProps> = props => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default CompleteSolution;
