import { FormScreenImg } from 'images/form-screen';
import styled from 'styled-components';

export const StyledForm = styled.form`
  width: 352px;
  height: 620px;

  padding: 40px 0px 0px 0px;

  background: #ffffff;
  border-radius: 10px 0px 0px 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1055px) {
    width: 512px;
    height: 652px;

    border-radius: 0px 0px 10px 10px;
  }
  @media (max-width: 671px) {
    width: 288px;
    height: 620px;
  }
`;

export const StyledTextArea = styled.textarea`
  width: 240px;
  height: 64px;

  margin: 4px 0px 0px 0px;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 14px;
  color: #525252;

  resize: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
`;

export const InfoContainer = styled.div`
  width: 448px;
  height: 620px;

  padding: 40px 0px 0px 0px;

  background-image: url(${FormScreenImg.formWave});
  border-radius: 0px 10px 10px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1055px) {
    width: 512px;
    height: 492px;

    border-radius: 10px 10px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 288px;
    height: 472px;
  }
`;

export const FormTitle = styled.div`
  width: 240px;

  font-weight: 500;
  font-size: ${({ customStyle }) => (customStyle.fontSize ? customStyle.fontSize : '16px')};
  margin: ${({ customStyle }) => (customStyle.margin ? customStyle.margin : '0px')};

  color: #525252;
`;

export const InfoDescription = styled.div`
  width: 320px;

  font-weight: 400;
  font-size: 14px;
  color: #525252;

  margin: 16px 0px 0px 0px;

  @media (max-width: 1055px) {
    width: 416px;
  }

  @media (max-width: 671px) {
    font-size: 12px;
    width: 240px;
  }
`;

export const FormInfo = styled.div`
  width: 246px;

  font-weight: 400;
  font-size: 10px;
  color: #8d8d8d;

  margin: 24px 0px 0px 0px;
`;
