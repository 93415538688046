import { yupResolver } from '@hookform/resolvers/yup';
import formService from 'api/services/formService';
import TextProcessing from 'components/text-processing';
import { styledText } from 'config/constants';
import ButtonVariants from 'model/enums/buttonVariants';
import { SmallFormFields } from 'model/enums/form-fields';
import { FormProperties } from 'model/enums/input-names';
import { TextVariants } from 'model/enums/text-variants';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { useMediaQuery } from 'react-responsive';
import { StyledButton } from 'themes/globalStyles';
import * as yup from 'yup';
import Input from './input';
import { Line, StyledDescription, StyledForm, StyledSmallForm } from './styled';

export interface SmallFormType {
  name?: string;
  email?: string;
}
interface SmallFormProps {
  text?: JSX.Element;
  customStyle?: any;
}

const SmallForm: React.FC<SmallFormProps> = ({ text, customStyle }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<SmallFormType>();
  const [isLoading, setIsLoading] = useState<number>(0);

  const schema = yup.object().shape({
    [FormProperties.NAME]: yup.string().required('Campo obrigatório').min(3, 'deve ter no mínimo 3 caracteres'),
    [FormProperties.EMAIL]: yup.string().required('Campo obrigatório').email('Email inválido'),
  });

  const methods = useForm({ resolver: yupResolver(schema) });

  const handleForm = (data: any) => {
    setIsLoading(1);

    setFormData(data);

    setTimeout(() => {
      setIsLoading(2);
    }, 1000);
    setTimeout(() => {
      setIsLoading(0);
      methods.reset();
      setFormData({});
    }, 2500);

    const fields = [
      { name: SmallFormFields.NAME, value: data?.name ?? '' },
      { name: SmallFormFields.EMAIL, value: data?.email ?? '' },
    ];
    const legalConsentOptions = {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Example Company to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Example Company.',
          },
        ],
      },
    };
    const getformData = formService.getSmallForm().then(res => {
      formService
        .sendSmallForm({
          id: res.guid,
          name: res.name,
          fields: fields,
          legalConsentOptions: legalConsentOptions,
          portalId: res.portalId,
        })
        .then(res => (window.location.href = 'https://comercial.giro.tech/obrigado-scd'));
    });
  };

  const sentProcess = () => {
    switch (isLoading) {
      case 0:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.DEFAULT }}>
            <TextProcessing text={t('global.button.send')} customStyle={styledText(TextVariants.QUATERNARY)} />
          </StyledButton>
        );
      case 1:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.DEFAULT }}>
            <ReactLoading type="spinningBubbles" width="24px" height="24px" />
          </StyledButton>
        );
      case 2:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.CHANGED }}>
            <TextProcessing text={t('global.button.sended')} customStyle={styledText(TextVariants.QUATERNARY)} />
          </StyledButton>
        );
      default:
        return;
    }
  };
  const isTablet = useMediaQuery({
    minWidth: 671,
  });

  return (
    <StyledSmallForm customStyle={customStyle.styledSmallForm}>
      <StyledDescription customStyle={customStyle.styledDescription}>{text}</StyledDescription>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(handleForm)} name="LP - Form Inicial">
          {isTablet && <Line customStyle={customStyle?.line} />}
          <Input
            name={FormProperties.NAME}
            required={true}
            htmlFor={FormProperties.NAME}
            label={t('global.smallForm.inputLabels.name')}
            styled={{ borderRadius: true, borderRight: true }}
            formData={formData}
            setFormData={setFormData}
            changeWidth={customStyle?.input?.width}
          />
          <Input
            name={FormProperties.EMAIL}
            required={true}
            htmlFor={FormProperties.EMAIL}
            label={t('global.smallForm.inputLabels.email')}
            formData={formData}
            setFormData={setFormData}
            changeWidth={customStyle?.input?.width}
          />
          {sentProcess()}
        </StyledForm>
      </FormProvider>
    </StyledSmallForm>
  );
};

export default SmallForm;
