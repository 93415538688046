import { useTranslation } from 'react-i18next';
import { arrayOfArray } from 'shared/util/array-utils';
import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface DiferentialsScreenProps {}

export interface DiferentialType {
  text: string;
}

const DiferentialsScreen: React.FC<DiferentialsScreenProps> = props => {
  const { t } = useTranslation();

  const getData: DiferentialType[] = t('diferentialsScreen.diferentials', { returnObjects: true });

  const splitData = arrayOfArray(getData, 3);

  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop diferentials={splitData} /> : <Mobile diferentials={splitData} />;
};

export default DiferentialsScreen;
