import TextProcessing from 'components/text-processing';
import { styledText } from 'config/constants';
import { TextVariants } from 'model/enums/text-variants';
import { SolutionDataType } from 'model/interfaces/cardType';
import { CardDescription, CardTitle, MobileTextBox, StyledCard, StyledImg } from '../styled';

interface MobileProps {
  icon: string;
  data: SolutionDataType;
}

const Mobile: React.FC<MobileProps> = ({ icon, data }) => {
  return (
    <StyledCard>
      <StyledImg customStyle={{ backgroundImage: icon }} />
      <MobileTextBox>
        <CardTitle>
          <TextProcessing text={data.title} customStyle={styledText(TextVariants.SECONDARY)} />
        </CardTitle>
        <CardDescription>
          <TextProcessing text={data.text} customStyle={styledText(TextVariants.QUINARY)} />
        </CardDescription>
      </MobileTextBox>
    </StyledCard>
  );
};

export default Mobile;
