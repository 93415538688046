import { FormScreenImg } from 'images/form-screen';
import { useFormContext } from 'react-hook-form';
import { FormType } from '..';
import { LabelInputBox, StyledError, StyledImg, StyledInput, StyledInputBox, StyledLabel } from './styled';

interface StyledType {
  borderRadius?: boolean;
  borderRight?: boolean;
}

interface InputProps {
  styled?: StyledType;
  label: JSX.Element;
  name: string;
  htmlFor: string;
  required: boolean;
  formData?: FormType;
  setFormData: any;
  maxLength?: number;
  mask?: any;
}

const Input: React.FC<InputProps> = ({ styled, label, name, required, htmlFor, formData, setFormData, maxLength, mask }) => {
  const isActive = formData?.[name]?.length > 0;

  const {
    register,
    formState: { errors },
    setError,
    reset,
  } = useFormContext();

  const handleClick = () => {
    setFormData({ ...formData, [name]: '' });
    reset({ [name]: '' });
  };

  const handleChange = (e, key) => {
    if (mask) {
      setFormData({ ...formData, [key]: mask(e.target.value) });
    } else {
      setFormData({ ...formData, [key]: e.target.value });
    }
  };

  return (
    <StyledInputBox theme={{ borderRadius: styled?.borderRadius }}>
      <LabelInputBox theme={{ borderRight: styled?.borderRight }}>
        <StyledLabel htmlFor={htmlFor} theme={{ isActive: isActive }}>
          {label}
        </StyledLabel>
        <StyledInput
          {...register(name, { required: required })}
          onChange={e => handleChange(e, name)}
          theme={{ isActive: isActive, hasError: errors[name]?.message.length > 0 }}
          name={name}
          value={formData?.[name] ?? ''}
          maxLength={maxLength}
          autocomplete="off"
        />

        {errors[name] ? (
          isActive ? (
            <StyledImg src={FormScreenImg.formClear} alt="clear" onClick={handleClick} />
          ) : (
            <StyledImg src={FormScreenImg.formError} alt="error" />
          )
        ) : (
          isActive && <StyledImg src={FormScreenImg.formClear} alt="clear" onClick={handleClick} />
        )}
      </LabelInputBox>
      <StyledError>{errors[name]?.message}</StyledError>
    </StyledInputBox>
  );
};

export default Input;
