import styled from 'styled-components';

export const LearningScreenContent = styled.div`
  width: 800px;

  display: flex;
  justify-content: space-between;

  margin: 96px 0px 0px;

  @media (max-width: 1055px) {
    width: 672px;
    height: auto;

    margin: 12px 0px 0px;

    padding: 24px 0px 24px;

    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 671px) {
    width: 320px;
  }
`;

export const StyledImg = styled.div`
  width: 384px;
  height: 400px;

  background-image: ${({ customStyle }) => `url(${customStyle.backgroundImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  border-radius: 10px;

  @media (max-width: 1055px) {
    width: 512px;
    height: 472px;
    margin: 24px 0px 0px 0px;
  }

  @media (max-width: 671px) {
    width: 272px;
    height: 252px;
  }
`;

export const InfoContainer = styled.div`
  width: 344px;

  display: flex;
  flex-direction: column;

  @media (max-width: 1055px) {
    width: 512px;
  }
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledDescription = styled.div`
  margin: 32px 0px 0px 0px;

  font-weight: 400;
  font-size: 16px;
  color: #525252;

  @media (max-width: 671px) {
    font-size: 12px;
    margin: 16px 0px 0px 0px;
  }
`;

export const StyledList = styled.ul`
  list-style-image: url(${''});
`;

export const StyledItem = styled.li`
  width: 296px;
  margin: 32px 0px 0px 0px;

  @media (max-width: 671px) {
    width: 240px;
    margin: 16px 0px 0px 0px;
  }
`;

export const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #525252;

  @media (max-width: 671px) {
    font-size: 14px;
  }
`;

export const ItemText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #525252;

  @media (max-width: 671px) {
    font-size: 12px;
  }
`;
