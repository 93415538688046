import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface BenefitsScreenProps {}

const BenefitsScreen: React.FC<BenefitsScreenProps> = props => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default BenefitsScreen;
