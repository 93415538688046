import { useFormContext } from 'react-hook-form';
import { SmallFormType } from '..';
import { LabelInputBox, StyledError, StyledInput, StyledInputBox, StyledLabel } from './styled';

interface StyledType {
  borderRadius?: boolean;
  borderRight?: boolean;
}

interface InputProps {
  styled?: StyledType;
  label: JSX.Element;
  name: string;
  htmlFor: string;
  required: boolean;
  formData?: SmallFormType;
  setFormData: (value: SmallFormType) => void;
  changeWidth?: boolean;
}

const Input: React.FC<InputProps> = ({ styled, label, name, required, htmlFor, formData, setFormData, changeWidth }) => {
  const isActive = formData?.[name]?.length > 0;

  const {
    register,
    formState: { errors },
    setError,
    reset,
  } = useFormContext();

  return (
    <StyledInputBox customStyle={{ borderRadius: styled?.borderRadius, width: changeWidth }}>
      <LabelInputBox customStyle={{ borderRight: styled?.borderRight, width: changeWidth }}>
        <StyledLabel htmlFor={htmlFor} customStyle={{ isActive: isActive, width: changeWidth }}>
          {label}
        </StyledLabel>

        <StyledInput
          {...register(name, { required: required })}
          onChange={e => setFormData({ ...formData, [name]: e.target.value })}
          customStyle={{ isActive: isActive, hasError: errors[name]?.message.length > 0, width: changeWidth }}
          name={name}
        />
      </LabelInputBox>
      <StyledError customStyle={{ width: changeWidth }}>{errors[name]?.message}</StyledError>
    </StyledInputBox>
  );
};

export default Input;
