import styled from 'styled-components';

export const DefaultText = styled.span`
  ${({ customStyle }) => customStyle.default?.width && `width:${customStyle.default.width};`}
  color: ${({ customStyle }) => customStyle.default.color};
  font-size: ${({ customStyle }) => customStyle.default.fontSize};
  font-weight: ${({ customStyle }) => customStyle.default.fontWeight};
  line-height: ${({ customStyle }) => customStyle.default.lineHeight};

  display: flex;

  @media (max-width: 671px) {
    ${({ customStyle }) => customStyle.mobile?.width && `width:${customStyle.mobile.width};`}
    color: ${({ customStyle }) => customStyle?.mobile?.color};
    font-size: ${({ customStyle }) => customStyle?.mobile?.fontSize};
    font-weight: ${({ customStyle }) => customStyle?.mobile?.fontWeight};
    line-height: ${({ customStyle }) => customStyle?.mobile?.lineHeight};
  }

  #color {
    ${({ customStyle }) => customStyle.custom?.color && `color:${customStyle.custom.color};`}
  }

  #bold {
    ${({ customStyle }) => customStyle.custom?.fontWeight && ` font-weight:${customStyle.custom.fontWeight};`}
  }
`;
