import styled from 'styled-components';

export const CompleteSolutionsContainer = styled.div`
  width: 800px;
  height: 342px;

  margin: 96px 0px 0px 0px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    margin: 12px 0px 0px 0px;
    padding: 24px 0px;
  }

  @media (max-width: 671px) {
    width: 272px;
    display: flex;
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  margin: 32px 0px 0px;
`;

export const CardContainer = styled.div`
  display: flex;

  justify-content: space-between;
  margin: 24px 0px 0px 0px;

  @media (max-width: 1055px) {
    flex-direction: column;
  }

  @media (max-width: 671px) {
  }
`;
