import { StyledPageContainer } from 'themes/globalStyles';
import AboutUsScreen from './about-us-screen';
import BenefitsScreen from './benefits-screen';
import CompleteSolution from './complete-solution';
import DiferentialsScreen from './diferentials-screen';
import Footer from './footer';
import FormScreen from './form-screen';
import Header from './header';
import OpenningScreen from './openning-screen';
import SolutionScreen from './solutions-screen';

interface LandingPageScreensProps {}

const LandingPageScreens: React.FC<LandingPageScreensProps> = props => {
  return (
    <StyledPageContainer customStyle={{ backgroundColor: '#F5F9FC' }}>
      <Header />
      <OpenningScreen />
      <SolutionScreen />
      <BenefitsScreen />
      <AboutUsScreen />
      <CompleteSolution />
      <DiferentialsScreen />
      <FormScreen />
      <Footer />
    </StyledPageContainer>
  );
};

export default LandingPageScreens;
