import styled from 'styled-components';

export const FooterContent = styled.div`
  width: 808px;
  height: 120px;

  display: flex;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 48px 0px 48px 0px;
  }
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledAlign = styled.div`
  display: flex;

  @media (max-width: 671px) {
    flex-direction: column;
  }
`;

export const StyledLogo = styled.img`
  width: 96px;
`;

export const ContactUsBox = styled.div`
  width: 200px;
  margin: 0px 0px 0px 48px;

  @media (max-width: 1055px) {
    width: 272px;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 671px) {
    width: 240px;
    align-items: center;
    margin: 24px 0px 24px 0px;
    text-align: center;
  }
`;

export const ContactUsTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
`;

export const ContactUsDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 8px 0px 0px 0px;
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
