import { useEffect } from 'react';
import ReactGA from 'react-ga';

import { useLocation } from 'react-router-dom';

interface WrapperProps {
  initialized: boolean;
  children: React.PropsWithChildren<any>;
}

export const Wrapper: React.FC<WrapperProps> = ({ initialized, children }) => {
  const location = useLocation();

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [initialized, location]);

  return children;
};
