import '../styles/constants/fonts/fonts.css';

export const StyledGuide = {
  color: {},
  font: {
    default: 'Poppins, sans-serif',
  },
  fontSize: {
    xxxs: '12px',
    xxs: '14px',
    xs: '16px',
    sm: '20px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    xxl: '48px',
    xxxl: '64px',
    display: '80px',
    giant: '96px',
  },
  lineHeight: {
    default: '100%',
    xs: '115%',
    sm: '120%',
    md: '133%',
    lg: '150%',
    xl: '170%',
    xxl: '200%',
  },
  button: {
    default: {
      background: '#82CFFF',
      color: '#fff',
    },
    changed: {
      background: '#33B1FF',
      color: '#fff',
    },
    disabled: {
      background: '#E0E0E0',
      color: '#8D8D8D',
    },
  },
};
