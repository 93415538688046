export const arrayOfArray = (base, max: number) => {
  const res: any = [[]];
  let page = 0;

  base.map((_el, idx) => {
    res[page] === undefined && (res[page] = []);
    res[page] = [...res[page], base[idx]];
    (idx + 1) % max === 0 && (page += 1);

    return res;
  });

  return res;
};
