import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import { StyledGuide } from 'themes/default';
import { GlobalStyle } from 'themes/globalStyles';
import App from './App';
import i18n from './config/i18n';
import * as serviceWorker from './serviceWorker';

const rootEl = document.getElementById('root');

ReactDOM.render(
  <ThemeProvider theme={StyledGuide}>
    <I18nextProvider i18n={i18n}>
      <GlobalStyle />
      <App />
      {/* If this slows down the app in dev disable it and enable when required  */}
      {/* {devTools} */}
    </I18nextProvider>
  </ThemeProvider>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
