import TextProcessing from 'components/text-processing';
import { styledSubtitle, styledText, styledTitle } from 'config/constants';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer } from 'themes/globalStyles';
import { BenefitContent, BoxText, BoxTitle } from '../styled';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = () => {
  const { t } = useTranslation();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: 'auto',
        margin: '0px',
        backgroundColor: '#F5F9FC',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <BenefitContent>
        <BoxTitle>
          <TextProcessing text={t('benefitsScreen.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
        </BoxTitle>
        <BoxText>
          <TextProcessing text={t('benefitsScreen.subTitle')} customStyle={styledSubtitle(TextVariants.PRIMARY)} />
          <TextProcessing text={t('benefitsScreen.text')} customStyle={styledText(TextVariants.QUINARY)} />
        </BoxText>
      </BenefitContent>
    </StyledScreenContainer>
  );
};

export default Mobile;
