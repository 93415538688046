import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import AppRoutes from 'shared/routes/routes';

const baseHref = '/';

interface IAppState {}

const App: React.FC<IAppState> = props => {
  const renderApp = () => {
    return <Route path={'/'} component={() => <AppRoutes match={'/'} />} />;
  };

  return (
    <BrowserRouter basename={baseHref}>
      <Route render={renderApp} />
    </BrowserRouter>
  );
};

export default App;
