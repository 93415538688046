import SmallForm from 'components/landing-page-screens/reusable-components/small-form';
import SocialMedia from 'components/landing-page-screens/reusable-components/social-media';
import TextProcessing from 'components/text-processing';
import { styledText } from 'config/constants';
import { GeneralImages } from 'images/general-img';
import { SocialMediaVariant } from 'model/enums/social-media-variants';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer } from 'themes/globalStyles';
import { ContactUsBox, ContactUsDescription, ContactUsTitle, FooterContent, LogoBox, StyledAlign, StyledLogo } from '../styled';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = props => {
  const { t } = useTranslation();

  const customStyle = {
    styledSmallForm: {
      width: '440px',
      height: '112px',
      margin: '24px 0px 0px 32px',
    },
    styledDescription: {
      width: '440px',
    },
    line: {
      changeWidth: true,
      borderRight: true,
    },
    input: {
      width: true,
    },
  };

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: 'auto',
        margin: '0px',
        backgroundColor: '#4589FF',
        flexDirection: 'column',
      }}
    >
      <FooterContent>
        <StyledAlign>
          <LogoBox>
            <StyledLogo src={GeneralImages.logoWhite} alt="logo" />
            <SocialMedia customStyle={{ margin: '8px 0px 0px' }} variant={SocialMediaVariant.SECONDARY} />
          </LogoBox>
          <ContactUsBox>
            <ContactUsTitle>
              <TextProcessing text={t('footer.contactUs.title')} customStyle={styledText(TextVariants.SENARY)} />
            </ContactUsTitle>

            <ContactUsDescription>
              <TextProcessing text={t('footer.contactUs.phone')} customStyle={styledText(TextVariants.TERTIARY)} />
            </ContactUsDescription>
            <ContactUsDescription>
              <TextProcessing text={t('footer.contactUs.adress')} customStyle={styledText(TextVariants.TERTIARY)} />
            </ContactUsDescription>
          </ContactUsBox>
        </StyledAlign>
        <SmallForm
          customStyle={customStyle}
          text={<TextProcessing text={t('global.smallForm.text')} customStyle={styledText(TextVariants.TERTIARY)} />}
        />
      </FooterContent>
    </StyledScreenContainer>
  );
};

export default Mobile;
