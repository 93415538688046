import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useResponsiveMobile from 'shared/util/useResponsiveHook';
import { StyledButton } from 'themes/globalStyles';
import * as yup from 'yup';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

import ReactLoading from 'react-loading';

import formService from 'api/services/formService';
import TextProcessing from 'components/text-processing';
import { styledText } from 'config/constants';
import ButtonVariants from 'model/enums/buttonVariants';
import { FormFields } from 'model/enums/form-fields';
import { FormProperties } from 'model/enums/input-names';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';

export interface FormType {
  name?: string;
  whatsapp?: string;
  email?: string;
  company?: string;
  description?: string;
}

interface FormScreenProps {}

const FormScreen: React.FC<FormScreenProps> = props => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormType>({});
  const [isLoading, setIsLoading] = useState<number>(0);
  const isMobile = useResponsiveMobile().isMobile;

  const schema = yup.object().shape({
    [FormProperties.NAME]: yup.string().required('Campo obrigatório').min(3, 'deve ter no mínimo 3 caracteres'),
    [FormProperties.WHATSAPP]: yup.string().required('Campo obrigatório'),
    [FormProperties.EMAIL]: yup.string().required('Campo obrigatório').email('Email inválido'),
    [FormProperties.COMPANY]: yup.string().required('Campo obrigatório'),
    [FormProperties.DESCRIPTION]: yup.string(),
  });
  const methods = useForm({ resolver: yupResolver(schema) });

  const handleForm = (data: any) => {
    setIsLoading(1);
    setFormData(data);
    setTimeout(() => {
      setIsLoading(2);
    }, 1000);
    setTimeout(() => {
      setIsLoading(0);
      methods.reset();
      setFormData({});
    }, 2500);

    const fields = [
      { name: FormFields.NAME, value: data?.name ?? '' },
      { name: FormFields.WHATSAPP, value: data?.whatsapp ?? '' },
      { name: FormFields.EMAIL, value: data?.email ?? '' },
      { name: FormFields.COMPANY, value: data?.company ?? '' },
      { name: FormFields.DESCRIPTION, value: data?.description ?? '' },
    ];
    const legalConsentOptions = {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Example Company to store and process my personal data.',
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Example Company.',
          },
        ],
      },
    };

    const getformData = formService.getForm().then(res => {
      formService
        .sendForm({
          id: res.guid,
          name: res.name,
          fields: fields,
          legalConsentOptions: legalConsentOptions,
          portalId: res.portalId,
        })
        .then(res => (window.location.href = 'https://comercial.giro.tech/obrigado-scd'));
    });
  };

  const sentProcess = () => {
    switch (isLoading) {
      case 0:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.DEFAULT, width: '240px', margin: '24px 0px 0px 0px' }}>
            <TextProcessing text={t('global.button.send')} customStyle={styledText(TextVariants.QUATERNARY)} />
          </StyledButton>
        );
      case 1:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.DEFAULT, width: '240px', margin: '24px 0px 0px 0px' }}>
            <ReactLoading type="spinningBubbles" width="24px" height="24px" />
          </StyledButton>
        );
      case 2:
        return (
          <StyledButton type="submit" customStyle={{ variant: ButtonVariants.CHANGED, width: '240px', margin: '24px 0px 0px 0px' }}>
            <TextProcessing text={t('global.button.sended')} customStyle={styledText(TextVariants.QUATERNARY)} />
          </StyledButton>
        );
      default:
        return;
    }
  };

  return !isMobile ? (
    <Desktop
      methods={methods}
      handleForm={handleForm}
      isLoading={isLoading}
      formData={formData}
      setFormData={setFormData}
      sentProcess={sentProcess}
    />
  ) : (
    <Mobile
      methods={methods}
      handleForm={handleForm}
      isLoading={isLoading}
      formData={formData}
      setFormData={setFormData}
      sentProcess={sentProcess}
    />
  );
};

export default FormScreen;
