import styled from 'styled-components';

export const StyledSmallForm = styled.div`
  width: ${({ customStyle }) => (customStyle?.width ? customStyle?.width : '512px')};
  height: ${({ customStyle }) => (customStyle?.height ? customStyle?.height : '124px')};

  margin: ${({ customStyle }) => (customStyle?.margin ? customStyle?.margin : '48px 0px 0px 64px')};

  display: flex;
  flex-direction: column;
  z-index: 1;

  @media (max-width: 1055px) {
    margin: ${({ customStyle }) => (customStyle?.margin ? customStyle?.margin : '32px 0px 0px 48px')};
  }
  @media (max-width: 671px) {
    width: 240px;
    height: auto;

    margin: ${({ customStyle }) => (customStyle?.margin ? '32px 0px 0px 0px' : '32px 0px 0px 24px')};
  }
`;

export const StyledDescription = styled.div`
  width: ${({ customStyle }) => (customStyle?.width ? customStyle?.width : '460px')};

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;

  @media (max-width: 671px) {
    width: 240px;
    font-size: 12px;
    text-align: ${({ customStyle }) => (customStyle?.width ? 'center' : '')};
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  height: 66px;
  margin: 16px 0px 0px 0px;

  display: flex;

  @media (max-width: 671px) {
    height: 216px;

    margin: 0px 0px 0px 0px;
    flex-direction: column;
  }
`;

export const Line = styled.div`
  width: ${({ customStyle }) => (customStyle?.changeWidth ? '156px' : '192px')};

  height: 30px;
  margin: 9px 0px 0px;
  position: absolute;
  border-right: 2px solid #82cfff;
  z-index: 1;
`;
