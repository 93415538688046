import { Wrapper } from 'components/Wrapper';
import LandingPage from 'features/landing-page';
import { Route, Switch } from 'react-router-dom';
import { useAnalytics } from 'shared/util/useAnalyticsHook';

const AppRoutes = ({ match }) => {
  const { initialized } = useAnalytics();
  return (
    <Switch>
      <Wrapper initialized={initialized}>
        <Route exact path={match.url} component={LandingPage} />
      </Wrapper>
    </Switch>
  );
};

export default AppRoutes;
