import { AxiosResponse } from 'axios';
import { FormId } from 'model/enums/form-fields';
import { GetForm, SendForm } from 'model/interfaces/formType';
import { api } from './api';

const formApi = () => {
  const getSmallForm = (): Promise<AxiosResponse<GetForm>> => {
    return api.post<GetForm>('/formsById', { id: FormId.SMALLFORM });
  };
  const sendSmallForm = (data: SendForm): Promise<AxiosResponse<SendForm>> => {
    return api.post<SendForm>('/submit', data);
  };
  const getForm = (): Promise<AxiosResponse<GetForm>> => {
    return api.post<GetForm>('/formsById', { id: FormId.FORM });
  };
  const sendForm = (data: SendForm): Promise<AxiosResponse<SendForm>> => {
    return api.post<SendForm>('/submit', data);
  };
  return {
    getSmallForm,
    sendSmallForm,
    getForm,
    sendForm,
  };
};

export default formApi();
