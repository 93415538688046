export enum FormFields {
  NAME = '0-2/nome',
  WHATSAPP = '0-2/whatsapp',
  EMAIL = 'e_mail_profissional',
  COMPANY = 'empresa',
  DESCRIPTION = 'conte_sobre_seu_produto_',
}
export enum FormId {
  SMALLFORM = '7852b1bd-5ec7-4136-965a-0a5d01e4f51d',
  FORM = '98cb49b8-007e-47d2-84b8-1eb3e6140b84',
}
export enum SmallFormFields {
  NAME = 'firstname',
  EMAIL = '0-2/e_mail_profissional',
}
