import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'config/constants';
import { DiferentialsImg } from 'images/diferentials-img';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer, StyledTitle } from 'themes/globalStyles';
import { DiferentialType } from '..';
import { CardList, CardListContainer, Column, DiferentialsScreenContent, StyledCard, StyledDescription, StyledIcon } from '../styled';

interface MobileProps {
  diferentials: DiferentialType[][];
}

const Mobile: React.FC<MobileProps> = ({ diferentials }) => {
  const { t } = useTranslation();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: 'auto',
        margin: '0px',
        backgroundColor: '#F5F9FC',
      }}
    >
      <DiferentialsScreenContent>
        <StyledTitle customStyle>
          <TextProcessing text={t('diferentialsScreen.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
        </StyledTitle>
        <StyledDescription>
          <TextProcessing text={t('diferentialsScreen.text')} customStyle={styledText(TextVariants.QUINARY)} />
        </StyledDescription>
        <CardListContainer>
          <CardList>
            {diferentials.map((column, key) => (
              <Column key={key}>
                {column.map((diferential, idx) => (
                  <StyledCard key={idx}>
                    <StyledIcon src={DiferentialsImg.listIcon} />
                    <TextProcessing text={diferential?.text} customStyle={styledText(TextVariants.QUINARY)} />
                  </StyledCard>
                ))}
              </Column>
            ))}
          </CardList>
        </CardListContainer>
      </DiferentialsScreenContent>
    </StyledScreenContainer>
  );
};

export default Mobile;
