import SocialMedia from 'components/landing-page-screens/reusable-components/social-media';
import { GeneralImages } from 'images/general-img';
import { StyledScreenContainer } from 'themes/globalStyles';
import { BoxImg, HeaderContent, StyledImg } from '../styled';

interface MobileProps {}

const Mobile: React.FC<MobileProps> = props => {
  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: '64px',
        margin: '0px',
        backgroundColor: '#F5F9FC',
      }}
    >
      <HeaderContent>
        <BoxImg>
          <StyledImg src={GeneralImages.logo} alt="logo" />
        </BoxImg>
        <SocialMedia customStyle />
      </HeaderContent>
    </StyledScreenContainer>
  );
};

export default Mobile;
