import TextProcessing from 'components/text-processing';
import { styledText, styledTitle } from 'config/constants';
import { AboutUsScreenImg } from 'images/about-us-screen';
import { TextVariants } from 'model/enums/text-variants';
import { useTranslation } from 'react-i18next';
import { StyledScreenContainer, StyledTitle } from 'themes/globalStyles';
import { InfoContainer, LearningScreenContent, StyledDescription, StyledImg } from '../styled';

interface DesktopProps {}

const Desktop: React.FC<DesktopProps> = () => {
  const { t } = useTranslation();

  return (
    <StyledScreenContainer
      customStyle={{
        width: '100%',
        height: '590px',
        margin: '0px',
        backgroundColor: '#fff',
        alignItems: 'flex-start',
      }}
    >
      <LearningScreenContent>
        <InfoContainer>
          <StyledTitle customStyle>
            <TextProcessing text={t('aboutUsScreen.title')} customStyle={styledTitle(TextVariants.SECONDARY)} />
          </StyledTitle>

          <StyledDescription>
            <TextProcessing text={t('aboutUsScreen.text')} customStyle={styledText(TextVariants.QUINARY)} />
          </StyledDescription>
        </InfoContainer>
        <StyledImg customStyle={{ backgroundImage: AboutUsScreenImg.aboutUsImg }} />
      </LearningScreenContent>
    </StyledScreenContainer>
  );
};

export default Desktop;
