import styled from 'styled-components';

export const DiferentialsScreenContent = styled.div`
  width: 800px;
  height: 514px;

  @media (max-width: 1055px) {
    width: 672px;
    height: auto;
    padding: 64px 0px 24px 80px;
  }
  @media (max-width: 671px) {
    display: flex;
    flex-direction: column;
    width: 320px;
    padding: 40px 0px 24px 24px;
  }
`;

export const StyledDescription = styled.div`
  width: 782px;

  margin: 32px 0px 0px 0px;

  font-weight: 400;
  font-size: 14px;
  color: #525252;

  @media (max-width: 1055px) {
    width: 512px;
  }
  @media (max-width: 671px) {
    width: 272px;
    font-size: 12px;
    margin: 16px 0px 0px 0px;
  }
`;

export const CardListContainer = styled.div`
  width: 800px;
  height: 242px;
  background: #f5f9fc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 32px 0px 0px;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
  }
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const CardList = styled.div`
  width: 656px;
  height: 132px;

  display: flex;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 0px;

    grid-template-columns: none;
    grid-gap: 0px;
    row-gap: 0px;
  }
  @media (max-width: 671px) {
    width: 272px;
  }
`;

export const StyledCard = styled.div`
  width: 308px;

  display: flex;
  align-items: center;

  @media (max-width: 1055px) {
    width: 512px;
    height: auto;
    padding: 8px 0px;

    margin: 16px 0px 0px 0px;
  }
  @media (max-width: 671px) {
    width: 272px;
    height: auto;
    margin: 16px 0px 0px 0px;
  }
`;

export const StyledIcon = styled.img`
  width: 8px;
  height: 8px;

  margin: 0px 8px 0px 0px;
`;

export const Column = styled.div`
  display: grid;
  grid-template-columns: 308px;
  grid-gap: 32px;

  @media (max-width: 1055px) {
    display: flex;
    flex-direction: column;
    grid-template-columns: 0px;
    grid-gap: 0px;
  }
  @media (max-width: 671px) {
  }
`;
