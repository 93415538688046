import useResponsiveMobile from 'shared/util/useResponsiveHook';
import { SolutionDataType } from '../layout/desktop';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface CardProps {
  data: SolutionDataType;
  icon: string;
}

const Card: React.FC<CardProps> = ({ data, icon }) => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop data={data} icon={icon} /> : <Mobile data={data} icon={icon} />;
};

export default Card;
