// import { SolutionsScreenImg } from 'images/solutions-screens';
import useResponsiveMobile from 'shared/util/useResponsiveHook';
import Desktop from './layout/desktop';
import Mobile from './layout/mobile';

interface SolutionScreenProps {}

const SolutionScreen: React.FC<SolutionScreenProps> = props => {
  const isMobile = useResponsiveMobile().isMobile;

  return !isMobile ? <Desktop /> : <Mobile />;
};

export default SolutionScreen;
