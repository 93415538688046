import formApi from 'api/formApi';
import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { SendForm } from 'model/interfaces/formType';

const formService = () => {
  const getSmallForm = async (): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await formApi.getSmallForm();
      console.log('result ', result);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const sendSmallForm = async (data: SendForm): Promise<SendForm> => {
    try {
      const result: AxiosResponse<SendForm> = await formApi.sendSmallForm(data);
      console.log('result ', result);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getForm = async (): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await formApi.getForm();
      console.log('result ', result);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const sendForm = async (data: SendForm): Promise<SendForm> => {
    try {
      const result: AxiosResponse<SendForm> = await formApi.sendForm(data);
      console.log('result ', result);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        // TODO: Talvez um midleware que exibe um toast de acordo com o status seria interessante.
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getSmallForm,
    getForm,
    sendSmallForm,
    sendForm,
  };
};

export default formService();
